<template>
    <div
        class="w-[306px] md:w-[320px] flex shadow-searchshadow z-70 right-[-30px] md:right-0 top-[8vh] rounded-2lg checkoutaddpopup absolute"
        v-if="show">
        <div
            class="w-[106px] md:w-[122px] bg-kbblue flex justify-center items-center h-[76px] md:h-[100px] rounded-l-2lg">
            <svg xmlns="http://www.w3.org/2000/svg" width="44.079" height="40.019" viewBox="0 0 44.079 40.019">
                <g id="Group_4963" data-name="Group 4963" transform="translate(-1418.489 -266.332)">
                    <g id="Group_4103" data-name="Group 4103" transform="translate(1420 267.832)">
                        <g id="Group_3491" data-name="Group 3491" transform="translate(0 0)">
                            <path
                                id="Path_2958"
                                data-name="Path 2958"
                                d="M216.467,221.826H200.548c-2.615,0-4.822-1.312-5.155-3.064L192.4,203.011c-.4-2.1,2.022-3.957,5.155-3.957h21.9c3.133,0,5.554,1.858,5.156,3.957l-2.993,15.751C221.289,220.515,219.081,221.826,216.467,221.826Z"
                                transform="translate(-192.356 -188.423)"
                                fill="none"
                                stroke="#fff"
                                stroke-miterlimit="10"
                                stroke-width="3"
                            />
                            <path id="Path_2959" data-name="Path 2959"
                                  d="M202.477,193.839l6.113-9.337c1.114-1.7,2.683-1.727,3.811-.061l6.366,9.4"
                                  transform="translate(-194.291 -183.208)" fill="none" stroke="#fff"
                                  stroke-miterlimit="10" stroke-width="3" />
                        </g>
                    </g>
                    <g id="Group_4105" data-name="Group 4105" transform="translate(1436.15 279.934)">
                        <circle id="Ellipse_287" data-name="Ellipse 287" cx="10" cy="10" r="10"
                                transform="translate(3.138 3.181)" fill="#5f4af4" />
                        <g id="check-circle-fill" transform="translate(0 0)">
                            <path
                                id="Path_173"
                                data-name="Path 173"
                                d="M26.417,13.209A13.209,13.209,0,1,1,13.209,0,13.209,13.209,0,0,1,26.417,13.209Zm-6.555-5a1.239,1.239,0,0,0-1.783.036l-5.734,7.306L8.889,12.091a1.239,1.239,0,0,0-1.75,1.75l4.368,4.37a1.239,1.239,0,0,0,1.782-.033l6.591-8.239A1.239,1.239,0,0,0,19.864,8.2Z"
                                fill="#70d44b"
                                fill-rule="evenodd"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <div
            class="bg-kb-mid-grey w-[200px] md:w-[278px] h-[76px] md:h-[100px] flex items-center justify-between px-1 md:px-4 rounded-r-2lg">
            <div class="">
                <div class="text-sm md:text-base font-bold whitespace-nowrap mb-2">Ürün Sepete Eklendi</div>
                <div class="text-xs md:text-sm font-bold whitespace-nowrap text-[#1F7EFF]">
                    <Link href="/sepetim">Sepete Git</Link>
                </div>
            </div>
            <button @click="show = false">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.887" height="20.887" viewBox="0 0 20.887 20.887">
                    <path
                        id="close-filled"
                        d="M12.693,2.25A10.379,10.379,0,0,0,2.25,12.693,10.379,10.379,0,0,0,12.693,23.137,10.379,10.379,0,0,0,23.137,12.693,10.379,10.379,0,0,0,12.693,2.25Zm4.028,15.665-4.028-4.028L8.665,17.915,7.472,16.722,11.5,12.693,7.472,8.665,8.665,7.472,12.693,11.5l4.028-4.028,1.194,1.194-4.028,4.028,4.028,4.028Z"
                        transform="translate(-2.25 -2.25)"
                        fill="#c7c7c7"
                    />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
    components: {
        Link
    },
    props: {
        // alert: {
        //     type: Object,
        //     required: true,
        // },
        show: {
            type: Boolean,
            required: true,
            default: false
        }
    }
};
</script>

<style scoped></style>
